<div class="page-title-area item-bg-two">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="page-title-content">
            <h1>{{ pageType }} Portal</h1>
            <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class="fas fa-chevron-right"></i></li>
              <li class="active">{{ pageType }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="tabs-data">
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="activeTabIndex"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab *ngFor="let note of noteList; let i = index" [label]="note.name">
        <ng-container *ngIf="activeTabIndex === i">
          <ng-container *ngFor="let desc of note?.note_desc">
            <ng-container *ngFor="let lang of desc?.note_langs">
              <ng-container *ngIf="lang.lang === currentLang">
                <div
                  class="ql-editor"
                  [innerHTML]="byPassHTML(lang.description)"
                ></div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>

