<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><img alt="company logo" width="20%" height="100%" [src]="logoPath" loading="lazy"></a>
        </h3>
        <ul>
            <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li> -->
            <!-- <li><a href="#" target="_blank" class="fab fa-twitter"></a></li> -->
            <li><a href="https://www.linkedin.com/company/maksellent" target="_blank" class="fab fa-linkedin-in"
                    aria-label="LinkedIn"></a></li>
            <!-- <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li> -->
        </ul>
        <div>
            <ul class="bottom-links" >
                <li  [routerLink]="'legal'"
                [queryParams]="{ type: 'service_terms' }"
                routerLinkActive="active">Legal</li>
                <li  [routerLink]="'privacy'"
                [queryParams]="{ type: 'privacy' }"
                routerLinkActive="active">Privacy</li>
            </ul>
        </div>
        <p>Copyright <i class="far fa-copyright"></i>2023 All Rights Reserved.</p>

    </div>
</footer>

<!-- <app-demo-sidebar></app-demo-sidebar> -->

<ngx-scrolltop></ngx-scrolltop>
