import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NoteService } from './notes.services';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/utility/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss',
  providers: [NoteService],
})
export class NotesComponent implements OnInit {
  noteList: any[] = [];
  note: any;
  currentLang = 'en';
  activeTabIndex = 0;
  pageType: string = '';

  constructor(
    private noteService: NoteService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer
  ) {
    // Listen for language changes
    this.sharedService.getLanguageChange().subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
  }

  ngOnInit() {
    this.initializePage();
  }

  /**
   * Handles initial page load and route query param processing.
   */
  private initializePage() {
    this.route.queryParams.subscribe((params) => {
      const typeFromQuery = params['type'];
      this.route.url.subscribe((urlSegments) => {
        const currentRoute = urlSegments[0]?.path; // Determine route ('legal' or 'privacy')
        this.pageType = currentRoute === 'legal' ? 'Legal' : 'Privacy';

        // Fetch data for the current route and query param
        this.loadSystemSettings(currentRoute, typeFromQuery);
      });
    });
  }

  /**
   * Fetches system settings based on the current route and query param.
   */
  private loadSystemSettings(routeType: string, queryType: string) {
    this.noteService.getSystemSettingBytype(routeType).subscribe((res) => {
      if (res?.status === 200 && res.data?.length) {
        this.noteList = res.data;

        // Match query param to active tab or fallback to the first tab
        const matchedIndex = this.noteList.findIndex((note) => note.code === queryType);
        this.activeTabIndex = matchedIndex !== -1 ? matchedIndex : 0;

        // Fetch initial tab data
        this.loadNoteData(this.noteList[this.activeTabIndex]?.code);
      } else {
        this.noteList = [];
      }
    });
  }

  /**
   * Loads note data for the specified code.
   */
  private loadNoteData(code: string) {
    if (!code) return;

    this.noteService.getNotesData(code).subscribe((res) => {
      if (res?.status === 200) {
        const selectedNote = this.noteList[this.activeTabIndex];
        selectedNote.note_desc = res.data;
      }
    });
  }

  /**
   * Handles tab change and updates the active tab and query params.
   */
  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    const selectedNote = this.noteList[this.activeTabIndex];

    if (selectedNote) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { type: selectedNote.code },
        queryParamsHandling: 'merge',
        state: { preserveScrollPosition: true }
      });

      this.loadNoteData(selectedNote.code);
    }
  }

  /**
   * Safely bypass HTML for rendering.
   */
  byPassHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
